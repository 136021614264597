<div class="container">
  <div class="left-container" *ngIf="showSearch">
    <modus-text-input #searchInput
                      label="{{ 'search' | translate }}"
                      include-search-icon
                      class="icon-size"
                      placeholder="{{ 'fileDisplayName' | translate }}"
                      clearable="true">
    </modus-text-input>
  </div>

  <div class="right-container">
    <modus-button color="primary"
                  (buttonClick)="onCreateCategoryClick()"
                  [disabled]="!categoryCreationEnabled">
      <p class="center-vertically">
        <span class="plus-sign">+</span> {{ "createCategory" | translate }}
      </p>
    </modus-button>
  </div>
</div>
