import {Component, OnDestroy, OnInit} from '@angular/core';
import {filter, Subject, takeUntil} from 'rxjs';
import {TagHeaderEventType, TagHeaderService} from './tag-header.service';
import {ActionCellEvent, ActionCellEventType, ActionCellService} from '../action-cell/action-cell.service';
import {TagCellEvent, TagCellEventType, TagCellService} from '../tag-cell/tag-cell.service';

@Component({
             selector: 'dms-tag-header',
             templateUrl: './tag-header.component.html',
             styleUrls: ['./tag-header.component.css']
           })
export class TagHeaderComponent implements OnInit, OnDestroy {

  private readonly stop$: Subject<void> = new Subject<void>();

  protected categoryCreationEnabled: boolean;

  protected showSearch: boolean = false;

  constructor(private readonly tagHeaderService: TagHeaderService,
              private readonly actionElementService: ActionCellService,
              private readonly tagColumnService: TagCellService) {
  }

  ngOnInit() {
    this.subscribeTagElementEvent();
    this.subscribeActionElementEvent();
    this.setCategoryCreationEnabled(true);
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }

  private readonly subscribeTagElementEvent = (): void => {
    this.tagColumnService
        .tagCellEvent$()
        .pipe(takeUntil(this.stop$))
        .subscribe(this.onTagElementEvent);
  }

  private readonly onTagElementEvent = (event: TagCellEvent): void => {
    if (event.type === TagCellEventType.PROPERTY_CREATED) {
      this.setCategoryCreationEnabled(true);
    }
  }

  private readonly subscribeActionElementEvent = (): void => {
    this.actionElementService
        .actionCellEvent$()
        .pipe(takeUntil(this.stop$),
              filter(this.concernsComponent))
        .subscribe(this.onActionElementEvent);
  }

  private readonly concernsComponent = ({key}: TagCellEvent | ActionCellEvent): boolean => {
    return key === undefined;
  }

  private readonly onActionElementEvent = (event: ActionCellEvent) => {
    if (event.type === ActionCellEventType.PROPERTY_CREATION_CANCEL) {
      this.setCategoryCreationEnabled(true);
    }
  }

  protected onCreateCategoryClick(): void {
    this.setCategoryCreationEnabled(false);
    this.tagHeaderService.publishTagHeaderEvent({
                                                  type: TagHeaderEventType.PROPERTY_CREATION_REQUEST
                                                });
  }

  private readonly setCategoryCreationEnabled = (value: boolean): void => {
    this.categoryCreationEnabled = value;
  }
}
